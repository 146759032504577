import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import IncidentList from "./IncidentList";
import IncidentCreate from "./IncidentCreate";
import IncidentEdit from "./IncidentEdit";
import IncidentShow from "./IncidentShow";

const resource = {
  list: IncidentList,
  create: IncidentCreate,
  edit: IncidentEdit,
  show: IncidentShow,
  icon: CalendarMonthIcon,
};

export default resource;