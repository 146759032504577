import {
  Create,
  DateInput,
  SelectInput,
  SimpleForm,
  TextInput,
  ReferenceInput,
  useDataProvider,
  useRedirect
} from "react-admin";
import {useState} from "react";
import GatekeeperInput from "../providerpractitioner/GatekeeperInput";
import InsuranceProviderInput from "./InsuranceProviderInput";
import Alert from '@mui/material/Alert';
import {
  humanaRegex,
  mcareRegex,
  simplyRegex,
  careplusRegex,
  devotedRegex,
  cignaRegex,
  aetnaRegex,
  unitedRegex,
  bcbsRegex,
  medicaRegex,
  preferredCareRegex,
  avmedRegex,
  freedomRegex,
} from "../constants";


const validatePatientCreation = (values) => {
  const required_fields = [
    ['first_name', 'First name'],
    ['last_name', 'Last name'],
    ['id_value', 'ID value'],
    ['id_type', 'ID type'],
    ['date_of_birth', 'Date of birth'],
    ['gatekeeper_id', 'Gatekeeper'],
    ['insurance_provider_id', 'Insurance provider'],
  ];
  const errors = {};
  for (const f of required_fields) {
    if (!values[f[0]]) {
      errors[f[0]] = `${f[1]} is required`;
    }
  }
  if (values.id_type === 'humana' && typeof values.id_value === 'string' && !humanaRegex.test(values.id_value)) {
    errors.id_value = 'Must be a valid Humana ID (H followed by 10 digits)';
  }
  if (values.id_type === 'medicare' && typeof values.id_value === 'string' && !mcareRegex.test(values.id_value)) {
    errors.id_value = 'Must be a valid Medicare ID';
  }
  if (values.id_type === 'simply' && typeof values.id_value === 'string' && !simplyRegex.test(values.id_value)) {
    errors.id_value = 'Must be a valid Simply Health ID';
  }
  if (values.id_type === 'careplus' && typeof values.id_value === 'string' && !careplusRegex.test(values.id_value)) {
    errors.id_value = 'Must be a valid Careplus ID';
  }
  if (values.id_type === 'devoted' && typeof values.id_value === 'string' && !devotedRegex.test(values.id_value)) {
    errors.id_value = 'Must be a valid Devoted ID';
  }
  if (values.id_type === 'cigna' && typeof values.id_value === 'string' && !cignaRegex.test(values.id_value)) {
    errors.id_value = 'Must be a valid Cigna ID';
  }
  if (values.id_type === 'aetna' && typeof values.id_value === 'string' && !aetnaRegex.test(values.id_value)) {
    errors.id_value = 'Must be a valid Aetna ID';
  }
  if (values.id_type === 'united' && typeof values.id_value === 'string' && !unitedRegex.test(values.id_value)) {
    errors.id_value = 'Must be a valid United Health ID';
  }
  if (values.id_type === 'bcbs' && typeof values.id_value === 'string' && !bcbsRegex.test(values.id_value)) {
    errors.id_value = 'Must be a valid Blue Cross Blue Shield ID';
  }
  if (values.id_type === 'medica' && typeof values.id_value === 'string' && !medicaRegex.test(values.id_value)) {
    errors.id_value = 'Must be a valid Medica Shield ID';
  }
  if (values.id_type === 'preferredcare' && typeof values.id_value === 'string' && !preferredCareRegex.test(values.id_value)) {
    errors.id_value = 'Must be a valid Preferred Care Partners ID';
  }
  if (values.id_type === 'avmed' && typeof values.id_value === 'string' && !avmedRegex.test(values.id_value)) {
    errors.id_value = 'Must be a valid AVMed ID';
  }
  if (values.id_type === 'freedom' && typeof values.id_value === 'string' && !freedomRegex.test(values.id_value)) {
    errors.id_value = 'Must be a valid Freedom ID';
  }
  return errors
};


const replaceRdStateRecord = (source, target) => Object.fromEntries(
  Object.entries(source).map(([field, value]) =>
    (String(value).startsWith('$replace-')) ? [field, target[value.replace('$replace-','')]] : [field, value]
  )
);


const PatientCreate =  () => {
  const dataProvider = useDataProvider();
  const [error, setError] = useState('');
  let redirect = useRedirect();
  const onSubmit = async (data) => {
    let p = {
      name: `${data.first_name} ${data.last_name}`,
      date_of_birth: data.date_of_birth,
      note: data.note,
      is_created_manually: true,
      names: {
        data: {
          first_name: data.first_name,
          last_name: data.last_name
        }
      },
      identifiers: {
        data: {
          id_type: data.id_type,
          id_value: data.id_value,
          is_created_manually: true
        }
      }
    };
    dataProvider.create('patient', {data:p})
      .then((p_response) => {
        const pr = p_response['data'];
        dataProvider.getManyReference(
          'patient_identifier',
          {target: 'patient_id', id: pr.id}
        )
          .then((i_response) => {
            const identifiers = i_response['data'];
            const eligibilities_data = {
              patient_id: pr.id,
              date_from: new Date(),
              date_to: new Date(),
              insurance_provider_id: data.insurance_provider_id,
              identifier_id: identifiers[0].id,
              lob_id: data.lob_id,
              gatekeeper_id: data.gatekeeper_id,
              is_created_manually: true
            };
            dataProvider.create('patient_eligibility', {data:eligibilities_data})
              .then((e_response) => {
                if (data.redirect_values !== undefined) {
                  const rd_page = data.redirect_values.page;
                  const rd_resource = data.redirect_values.resource || null;
                  const rd_resource_id = data.redirect_values.resource_id || null;
                  const rd_argument = data.redirect_values.argument || {};
                  const rd_object = data.redirect_values.state || {};
                  const rd_state = (rd_object.record !== undefined) ? {...rd_object, record: replaceRdStateRecord(rd_object.record, pr) } : rd_object;
                  redirect(rd_page, rd_resource, rd_resource_id, rd_argument, rd_state);
                }
                else {
                  redirect('show', 'patient', pr.id);
                }
              })
              .catch(error => {
                console.log('caught at creating eligibility:', error);
              });
          })
          .catch(error => {
            console.log('caught at getting identifiers:', error);
          });
      })
      .catch(error => {
        console.log('caught at creating patient:', error);
        if (error?.message?.includes('patient_uk')) {
          setError('A patient with this ID Type / Id Value already exists');
        }
      });
  };
  return (
    <Create>
      {error && <Alert severity="error">{error}</Alert>}
      <SimpleForm onSubmit={onSubmit} validate={validatePatientCreation}>
        <TextInput label='First Name' source="first_name"/>
        <TextInput label='Last Name' source="last_name"/>
        <InsuranceProviderInput label="Insurance provider" source="insurance_provider_id"/>
        <InsuranceProviderInput label="ID type" source="id_type"/>
        <TextInput label='ID' source="id_value"/>
        <DateInput label='Date of birth' source="date_of_birth"/>
        <GatekeeperInput label='PCP' source='gatekeeper_id'/>
        <ReferenceInput label='Line of business' source="lob_id" reference="line_of_business">
          <SelectInput label='Line of business' optionText="description" />
        </ReferenceInput>
        <TextInput label='Note' source='note' />
      </SimpleForm>
    </Create>
  );
}

export default PatientCreate;
