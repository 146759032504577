import {
  Edit,
  SaveButton,
  SimpleForm,
  TextInput,
  Toolbar,
  SimpleFormIterator,
  ReferenceInput,
  SelectInput,
  BooleanInput,
  required,
  useRecordContext,
  useDataProvider,
  useRedirect,
  useNotify,
} from "react-admin";
import {ReferenceManyInput} from "@react-admin/ra-relationships";
import ProviderPractitionerInput from '../providerpractitioner/ProviderPractitionerInput';

const userRoleKey = () => {
  return "@@ra-many/user/user_role/user_id";
};

const officeManagerKey = () => {
  return "@@ra-many/user/office_manager_provider/user_id";
};

const validateUserRoleUpdate = (items) => {
  const errors = [];
  const errorProvPractitioner = (items.filter((item) => item.role_id === "prov_practitioner").length > 0 && items.length > 1);
  const errorOfficeManager = (items.filter((item) => item.role_id === "office_manager").length > 0 && items.length > 1);
  const errorDefault = (items.filter((item) => item.is_default).length !== 1);
  if (items.length === 0) {
    errors.push('Required');
  }
  for (const i in items) {
    const item = items[i];
    const itemErrors = {};
    if (errorProvPractitioner && item.role_id !== "prov_practitioner") {
      itemErrors['role_id'] = 'A "Provider practitioner" user cannot have more roles';
    }
    if (errorOfficeManager && item.role_id !== "office_manager") {
      itemErrors['role_id'] = 'An "Office manager" user cannot have more roles';
    }
    if (errorDefault) {
      itemErrors['role_id'] = 'A user must have exactly one role by default';
    }
    errors.push(itemErrors);
  }
  return errors.find(e => Object.keys(e).length > 0) !== undefined ? errors : [];
};

const validateOfficeManagerUpdate = (items, isOfficeManager) => {
  const errors = [];
  if (isOfficeManager && items.length === 0) {
    errors.push('Required');
  }
  else if (!isOfficeManager && items.length !== 0) {
    for (const i in items) {
      errors.push({'provider_practitioner_id': 'Related practitioners should only be indicated for office managers'});
    }
  }
  return errors.find(e => Object.keys(e).length > 0) !== undefined ? errors : [];
};

const validateUserUpdate = (values) => {
  const required_fields = [
    ['name', 'Name'],
    ['email', 'Email'],
  ];
  const errors = {};
  for (const f of required_fields) {
    if (!values[f[0]]) {
      errors[f[0]] = `${f[1]} is required`;
    }
  }
  const userRoleErrors = validateUserRoleUpdate(values[userRoleKey()][0]["user_role"]);
  if (userRoleErrors.length > 0) {
    errors[userRoleKey()] = [{"user_role": userRoleErrors}];
  }
  const isOfficeManager = (values[userRoleKey()][0]["user_role"].filter((item) => item.role_id === "office_manager").length > 0);
  const officeManagerErrors = validateOfficeManagerUpdate(values[officeManagerKey()][0]["office_manager_provider"], isOfficeManager);
  if (officeManagerErrors.length > 0) {
    errors[officeManagerKey()] = [{"office_manager_provider": officeManagerErrors}];
  }
  return errors;
};

const UserEditToolbar = () => {
  return (
    <Toolbar>
      <SaveButton/>
    </Toolbar>
  )
}

export const UserEditForm = () => {
  const notify = useNotify();
  const redirect = useRedirect();
  const user = useRecordContext();
  const dataProvider = useDataProvider();

  const onSubmit = async (data) => {
    const getReferencedByKey = (key, element_name) => {
      return data[key][0][element_name];
    };
    const getReferencedIdsByKey = (key, element_name) => {
      return getReferencedByKey(key, element_name).map((e) => e.id).filter((v) => v !== undefined && v !== null);
    };

    const upsertUserRoles = async () => {
      let rolePromises = [];

      const { data: currentRoles } = await dataProvider.getList(
        'user_role', { filter: { user_id: user.id } }
      );

      const userRoles = getReferencedByKey(userRoleKey(), "user_role");
      const userRoleIds = getReferencedIdsByKey(userRoleKey(), "user_role");

      currentRoles.forEach((userRole) => {
        if (userRoleIds.indexOf(userRole.id) < 0) {
          rolePromises.push(
            dataProvider.delete('user_role', {id: userRole.id})
          );
        }
      });

      userRoles.forEach((userRole) => {
        if (userRole.id === undefined) {
          rolePromises.push(
            dataProvider.create('user_role', {data: {...userRole, user_id: user.id}})
          );
        }
        else {
          rolePromises.push(
            dataProvider.update('user_role', {id: userRole.id, data: userRole})
          );
        }
      });

      return rolePromises;
    };

    const upsertOfficeManagerProviders = async () => {
      let officeManagerPromises = [];

      const { data: currentManagerProviders } = await dataProvider.getList(
        'office_manager_provider', { filter: { user_id: user.id } }
      );

      const managerProviders = getReferencedByKey(officeManagerKey(), "office_manager_provider");
      const managerProviderIds = getReferencedIdsByKey(officeManagerKey(), "office_manager_provider");

      currentManagerProviders.forEach((managerProvider) => {
        if (managerProviderIds.indexOf(managerProvider.id) < 0) {
          officeManagerPromises.push(
            dataProvider.delete('office_manager_provider', {id: managerProvider.id})
          );
        }
      });

      managerProviders.forEach((managerProvider) => {
        if (managerProvider.id === undefined) {
          officeManagerPromises.push(
            dataProvider.create('office_manager_provider', {data: {...managerProvider, user_id: user.id}})
          );
        }
        else {
          officeManagerPromises.push(
            dataProvider.update('office_manager_provider', {id: managerProvider.id, data: managerProvider})
          );
        }
      });

      return officeManagerPromises;
    };

    const updateUserValues = async () => {
      let userPromises = [];

      delete data[userRoleKey()];
      delete data[officeManagerKey()];

      userPromises.push(
        dataProvider.update('user', {id: user.id, data: data})
      );

      return userPromises;
    };

    const userRolesPromises = await upsertUserRoles();
    const managerProvidersPromises = await upsertOfficeManagerProviders();
    const userPromises = await updateUserValues();
    const promises = [...new Set([...userRolesPromises, ...managerProvidersPromises, ...userPromises])];

    Promise.allSettled(promises)
    .then(() => {
      notify('User updated', { type: 'success' });
    })
    .catch((e) => {
      notify('Error updating user', { type: 'error' });
      console.log('error when updating user');
      console.log(e)
    })
    .finally(() => {
      redirect('show', 'user', user.id);
    });
  };

  return (
    <SimpleForm toolbar={<UserEditToolbar/>} onSubmit={onSubmit} validate={validateUserUpdate}>
      <TextInput source="name" />
      <TextInput source="email" />
      <ReferenceManyInput reference='user_role' target='user_id' >
        <SimpleFormIterator inline>
          <ReferenceInput
            source="role_id"
            reference="role"
            sort={{ field: "description", order: "ASC" }}
          >
            <SelectInput optionText="description" validate={required()} />
          </ReferenceInput>
          <BooleanInput source="is_default" />
        </SimpleFormIterator>
      </ReferenceManyInput>
      <ReferenceManyInput reference='office_manager_provider' target='user_id' >
        <SimpleFormIterator inline>
          <ProviderPractitionerInput source="provider_practitioner_id" label="Provider" />
        </SimpleFormIterator>
      </ReferenceManyInput>
    </SimpleForm>
  );
};

export const UserEdit = () => (
  <Edit mutationMode='pessimistic'>
    <UserEditForm />
  </Edit>
);

export default UserEdit;
