import {
  Edit,
  SimpleForm,
  TopToolbar,
  Toolbar,
  ShowButton,
  SaveButton,
  TextInput,
  ReferenceInput,
  SelectInput,
  AutocompleteInput,
} from 'react-admin';
import {userWithPermissions, isOfficeUser} from "../components/domain";

const IncidentOfficeUserEditActions = () => (
  <TopToolbar>
      <ShowButton />
  </TopToolbar>
);

const IncidentEditUserToolbar = props => (
  <Toolbar {...props} >
    <SaveButton />
  </Toolbar>
);

const IncidentOfficeUserEditToolbar = props => {
  const transformIncident = data => ({
    ...data,
    status: 'resolved_prov'
  });

  return (
    <Toolbar {...props} >
      <SaveButton />
      <SaveButton
        type="button"
        label="Save and resolve"
        transform={transformIncident}
        variant="text"
      />
    </Toolbar>
  );
};

const IncidentUserEdit = () => (
  <Edit>
    <SimpleForm toolbar={<IncidentEditUserToolbar />}>
      <ReferenceInput
        source="type"
        reference="incident_type"
        sort={{ field: "description", order: "ASC" }}
      >
        <SelectInput optionText="description" disabled={true} />
      </ReferenceInput>
      <ReferenceInput
        source="appointment_id"
        reference="appointment"
        sort={{ field: "date", order: "DESC" }}
      >
        <AutocompleteInput
          optionText={(appointment) => appointment?.id // the empty choice is { id: '' }
            ? `${appointment.date} ${appointment.patient ? appointment.patient.name : appointment.patient_name}` :
            ''}
          filterToQuery={(searchText) => ({ "patient_name@_ilike,patient#name@_ilike": searchText })}
          disabled={true}
        />
      </ReferenceInput>
      <TextInput fullWidth multiline source="description" />
    </SimpleForm>
  </Edit>
);

const IncidentOfficeUserEdit = () => (
  <Edit actions={<IncidentOfficeUserEditActions />}>
    <SimpleForm toolbar={<IncidentOfficeUserEditToolbar />}>
      <ReferenceInput
        source="type"
        reference="incident_type"
        sort={{ field: "description", order: "ASC" }}
      >
        <SelectInput optionText="description" disabled={true} />
      </ReferenceInput>
      <ReferenceInput
        source="appointment_id"
        reference="appointment"
        sort={{ field: "date", order: "DESC" }}
      >
        <AutocompleteInput
          optionText={(appointment) => appointment?.id // the empty choice is { id: '' }
            ? `${appointment.date} ${appointment.patient ? appointment.patient.name : appointment.patient_name}` :
            ''}
          filterToQuery={(searchText) => ({ "patient_name@_ilike,patient#name@_ilike": searchText })}
          disabled={true}
        />
      </ReferenceInput>
      <TextInput fullWidth multiline source="description" disabled={true} />
      <TextInput fullWidth multiline source="response" />
    </SimpleForm>
  </Edit>
);

const IncidentEdit = () => {
  if (!userWithPermissions()) return null;

  return (
    isOfficeUser() ?
    <IncidentOfficeUserEdit /> :
    <IncidentUserEdit />
  );
};

export default IncidentEdit;
