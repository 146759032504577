// In theme.js
import { defaultTheme } from "react-admin";
import { createTheme } from '@mui/material/styles';
import { red, lightBlue, amber, green } from '@mui/material/colors';
import merge from "lodash/merge";
import {VITE_THEME_SECONDARY_MAIN} from "./config";

export const theme = createTheme(
  merge({}, defaultTheme, {
    palette: {
      secondary: {
        main: VITE_THEME_SECONDARY_MAIN,
      },
      sapiensSuccess: {
        light: green[50],
        main: green[50],
        dark: green[50],
        contrastText: green[900],
      },
      sapiensWarning: {
        light: amber[50],
        main: amber[50],
        dark: amber[50],
        contrastText: amber[900],
      },
      sapiensInfo: {
        light: lightBlue[50],
        main: lightBlue[50],
        dark: lightBlue[50],
        contrastText: lightBlue[900],
      },
      sapiensError: {
        light: red[50],
        main: red[50],
        dark: red[50],
        contrastText: red[900],
      },
    },
    typography: {
      fontFamily: [
        'Inter'
      ].join(','),
      h4: {
        fontSize: '34px',
        fontStyle: 'normal',
        fontWeight: '500',
        lineHeight: '123.5%',
        letterSpacing: '0.25px',
      },
      h5: {
        fontSize: '24px',
        fontStyle: 'normal',
        fontWeight: '500',
        lineHeight: '133.4%',
      },
    },
    components: {
      ...defaultTheme.components,
      MuiButtonBase: {
        defaultProps: {
          disableRipple: true,
        },
      },
      MuiBackdrop: {
        styleOverrides: {
          root: {
            backgroundColor: "rgba(0, 0, 0, 0)",
          },
        },
      },
      MuiFormControl: {
        styleOverrides: {
          root: {
            display: "flex",
            width: "87%",
            flexDirection: "column",
            alignItems: "flex-start",
            "& label.Mui-focused": {
              color: "rgba(0, 0, 0, 0.6)",
            },
          },
          fullWidth: {
            width: "100%",
          },
        },
      },
      MuiInputBase: {
        styleOverrides: {
          formControl: {
            width: "100%",
            backgroundColor: "#FFFFFF !important",
            borderBottom: "1px solid rgba(34, 35, 35, 0.50)",
            "&:before": {
              borderBottom: "0px !important",
            },
            "&:after": {
              borderBottom: "0px !important",
            },
            "&.Mui-focused": {
              borderBottom: "1px solid #6EC0CB",
            },
          },
          input: {
            "&:focus": {
              backgroundColor: "#FFFFFF !important",
            },
          },
          multiline: {
            display: "flex",
            padding: "16px !important",
            justifyContent: "center",
            alignItems: "center",
            gap: "10px",
            alignSelf: "stretch",
            borderRadius: "8px !important",
            border: "1px solid rgba(34, 35, 35, 0.50)",
            background: "#FFFFFF",
            "&.Mui-focused": {
              border: "1px solid #6EC0CB",
            },
          },
          inputMultiline: {
            flex: "1 0 0",
            color: "#222323",
            fontFamily: "Inter",
            fontSize: "14px",
            fontStyle: "normal",
            fontWeight: "400",
            lineHeight: "143%",
            letterSpacing: "0.17px",
          },
        },
      },
      MuiDialog: {
        styleOverrides: {
          paper: {
            display: "flex",
            width: "708px",
            maxWidth: "708px",
            padding: "32px",
            flexDirection: "column",
            alignItems: "flex-start",
            gap: "10px",
            borderRadius: "8px",
            background: "#FFFFFF",
            boxShadow: "0px 8px 42px 7px rgba(0, 0, 0, 0.12), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 10px 14px -6px rgba(0, 0, 0, 0.20)",
            "& .RaLabeled-label": {
              textTransform: "uppercase",
            },
            "& .MuiToolbar-root": {
              background: "#FFFFFF",
              "& .Mui-disabled": {
                color: "rgba(0, 0, 0, 0.26) !important",
                boxShadow: "none !important",
                backgroundColor: "rgba(0, 0, 0, 0.12) !important",
              },
              "& .MuiButton-containedPrimary": {
                display: "flex",
                padding: "6px 16px",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: "4px",
                background: "#222323",
                boxShadow: "none !important",
                "& .MuiSvgIcon-root": {
                  display: "none",
                },
              },
            },
          },
        },
      },
      MuiMenu: {
        styleOverrides: {
          paper: {
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            gap: "8px",
            borderRadius: "0px 0px 8px 8px !important",
            borderRight: "1px solid rgba(34, 35, 35, 0.50)",
            borderBottom: "1px solid rgba(34, 35, 35, 0.50)",
            borderLeft: "1px solid rgba(34, 35, 35, 0.50)",
            borderTop: "1px solid #6EC0CB",
            background: "#FFFFFF",
            boxShadow: "none !important",
          },
        },
      },
      MuiList: {
        styleOverrides: {
          root: {
            width: "100%",
            boxShadow: "none !important",
          },
        },
      },
      MuiTableRow: {
        styleOverrides: {
          root: {
            "&:has(> .MuiTableCell-root .RaDatagrid-expanded):not(.RaDatagrid-headerRow)": {
              backgroundColor: "#FAFAFA",
              borderLeft: "4px solid #6EC0CB",
              "& .MuiTableCell-root": {
                borderBottom: "0px",
              },
            },
          },
        },
      },
      MuiMenuItem: {
        styleOverrides: {
          root: {
            display: "flex",
            height: "32px",
            flex: "1 0 0",
            color: "rgba(34, 35, 35, 0.64)",
            fontSize: "14px",
            fontStyle: "normal",
            fontWeight: "400",
            lineHeight: "143%",
            letterSpacing: "0.17px",
          },
        },
      },
      MuiAutocomplete: {
        styleOverrides: {
          root: {
            width: "100%",
          },
          tag: {
            display: "flex",
            padding: "4px 8px",
            alignItems: "center",
            borderRadius: "8px",
            background: "#FAFAFA",
          },
          popper: {
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            gap: "8px",
            background: "#FFFFFF",
            boxShadow: "none !important",
          },
          paper: {
            width: "100%",
            boxShadow: "none !important",
            borderRadius: "0px 0px 8px 8px !important",
            borderRight: "1px solid rgba(34, 35, 35, 0.50) !important",
            borderBottom: "1px solid rgba(34, 35, 35, 0.50) !important",
            borderLeft: "1px solid rgba(34, 35, 35, 0.50) !important",
          },
          option: {
            display: "flex",
            height: "32px",
            flex: "1 0 0",
            color: "rgba(34, 35, 35, 0.64)",
            fontSize: "14px",
            fontStyle: "normal",
            fontWeight: "400",
            lineHeight: "143%",
            letterSpacing: "0.17px",
          },
        },
      },
      MuiChip: {
        styleOverrides: {
          deleteIcon: {
            display: "flex",
            width: "16px",
            height: "16px",
            justifyContent: "center",
            alignItems: "center",
            opacity: "0.4",
          },
        },
      },
      RaLayout: {
        styleOverrides: {
          root: {
            "& .RaLayout-content": {
              backgroundColor: "white",
            },
          },
        },
      },
      RaMenuItem: {
        styleOverrides: {
          root: {
            width: "100%",
            backgroundColor: "#fafafa",
            "&:hover": {
              backgroundColor: "#fafafa",
            },
            "& .MuiTypography-root": {
              fontSize: "14px",
            },
            "& .CustomMenuItem": {
              color: "#222323",
              opacity: "0.6",
              "&:hover": {
                opacity: "1.0",
              },
              "& .RaMenuItem-itemButton": {
                padding: "8px 0",
              },
              "& .RaMenuItem-button": {
                "&:hover": {
                  backgroundColor: "#fafafa",
                },
              },
            },
            "& .CustomMenuItemActive": {},
            "& .CustomMenuItemMainActive": {
              width: "calc(100% + 16px)",
              marginLeft: "-16px",
              paddingLeft: "12px",
              borderLeft: "4px solid #6EC0CB",
            },
            "& .CustomMenuItemMainTreeActive": {
              opacity: "1.0",
            },
            "& .CustomMenuSubItemActive": {
              width: "calc(100% + 20px)",
              backgroundColor: "#2223230A",
              borderRadius: "8px",
              padding: "0 30px",
              marginLeft: "-30px",
            },
          },
        },
      },
      RaLabeled: {
        styleOverrides: {
          root: {
            "&:has(> div.MuiFormControl-fullWidth)": {
              width: "100%",
            },
          },
        },
      },
      RaDatagrid: {
        styleOverrides: {
          root: {
            "& .RaDatagrid-expandedPanel": {
              backgroundColor: "#FAFAFA",
              borderLeft: "4px solid #6EC0CB",
              "& .RaSimpleShowLayout-stack": {
                marginLeft: '84px',
                "& .MuiTypography-body1": {
                  color: "#222323",
                  fontSize: "12px",
                  fontStyle: "normal",
                  fontWeight: "400",
                  lineHeight: "266%",
                  letterSpacing: "1px",
                  textTransform: "uppercase",
                },
                "& .MuiTypography-body2": {
                  width: '50%',
                  whiteSpace: 'pre-wrap',
                  display:'inline-block',
                  display: "flex",
                  padding: "16px",
                  alignItems: "center",
                  gap: "10px",
                  alignSelf: "stretch",
                  borderRadius: "8px",
                  border: "1px solid rgba(34, 35, 35, 0.50)",
                  background: "#FFFFFF",
                },
              },
            },
          },
        },
      },
    },
  })
);
