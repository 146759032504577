import {trim} from "lodash/string";

export const VITE_HASURA_GRAPHQL_URL = import.meta.env.VITE_HASURA_GRAPHQL_URL;

if (VITE_HASURA_GRAPHQL_URL === undefined) {
  console.error(`Env variable VITE_HASURA_GRAPHQL_URL mut be set`);
}

export const VITE_COGNITO_USERPOOL_ID = import.meta.env.VITE_COGNITO_USERPOOL_ID

if (VITE_COGNITO_USERPOOL_ID === undefined) {
  console.error(`Env variable VITE_COGNITO_USERPOOL_ID mut be set`);
}

export const VITE_COGNITO_APP_CLIENT_ID = import.meta.env.VITE_COGNITO_APP_CLIENT_ID

if (VITE_COGNITO_APP_CLIENT_ID === undefined) {
  console.error(`Env variable VITE_COGNITO_APP_CLIENT_ID mut be set`);
}

export const VITE_COGNITO_HOSTED_UI_URL = import.meta.env.VITE_COGNITO_HOSTED_UI_URL

if (VITE_COGNITO_HOSTED_UI_URL === undefined) {
  console.error(`Env variable VITE_COGNITO_HOSTED_UI_URL mut be set`);
}

export const VITE_SAPIENS_BI_URL = import.meta.env.VITE_SAPIENS_BI_URL

if (VITE_SAPIENS_BI_URL === undefined) {
  console.error(`Env variable VITE_SAPIENS_BI_URL mut be set`);
}


export const VITE_SENTRY_DSN = import.meta.env.VITE_SENTRY_DSN

if (VITE_SENTRY_DSN === undefined) {
  console.info(`Env variable VITE_SENTRY_DSN is not set. Not reporting anything to Sentry. If this is a local development runtime, this is expected.`);
}

export const VITE_SENTRY_ENVIRONMENT = import.meta.env.VITE_SENTRY_ENVIRONMENT === undefined ? 'local' : import.meta.env.VITE_SENTRY_ENVIRONMENT

export const AWS_COMMIT_ID = import.meta.env.AWS_COMMIT_ID === undefined ? 'local' : import.meta.env.AWS_COMMIT_ID

export const VITE_SAPIENS_BI_SECRET = import.meta.env.VITE_SAPIENS_BI_SECRET

if (VITE_SAPIENS_BI_SECRET === undefined) {
  console.error(`Env variable VITE_SAPIENS_BI_SECRET must be set to be able to access the BI dashboard`);
}

export const VITE_MSO_LOGO_URL = import.meta.env.VITE_MSO_LOGO_URL

if (VITE_MSO_LOGO_URL === undefined) {
  console.info(`Env variable VITE_MSO_LOGO_URL is not set. We will not show any logo on rec_report exports`);
}

export const VITE_CUBEJS_API_URL = import.meta.env.VITE_CUBEJS_API_URL
if (VITE_CUBEJS_API_URL === undefined) {
  console.warn(`Env variable VITE_CUBEJS_API_URL is not set. You will not be able to see any graphs on the dashboards`);
}

export const VITE_CUBEJS_TOKEN = import.meta.env.VITE_CUBEJS_TOKEN
if (VITE_CUBEJS_TOKEN === undefined) {
  console.warn(`Env variable VITE_CUBEJS_TOKEN is not set. This is the secret key to access the Cube.js API. You will not be able to see any graphs on the dashboards`);
}

export const VITE_MUIX_LICENSE_KEY = import.meta.env.VITE_MUIX_LICENSE_KEY
if (VITE_MUIX_LICENSE_KEY === undefined) {
  console.info(`Env variable VITE_MUIX_LICENSE_KEY is not set. MUI X components will show a warning but will function.`);
}


const hexColorRegEx = /^#[\da-fA-F]{6}$/g

export const VITE_THEME_SECONDARY_MAIN =
  (import.meta.env.VITE_THEME_SECONDARY_MAIN === undefined ||
    trim(import.meta.env.VITE_THEME_SECONDARY_MAIN).match(hexColorRegEx) === null)
    ? '#001626'
    : trim(import.meta.env.VITE_THEME_SECONDARY_MAIN);

// console.debug('### Raw Vite environment VITE_THEME_SECONDARY_MAIN ###', import.meta.env.VITE_THEME_SECONDARY_MAIN);
// console.debug('### Exported VITE_THEME_SECONDARY_MAIN value ###', VITE_THEME_SECONDARY_MAIN);

export const VITE_DEFAULT_LIST_PER_PAGE = import.meta.env.VITE_DEFAULT_LIST_PER_PAGE === undefined ? 25 : parseInt(import.meta.env.VITE_DEFAULT_LIST_PER_PAGE);

export const VITE_DEFAULT_LISTSHOW_PER_PAGE = import.meta.env.VITE_DEFAULT_LISTSHOW_PER_PAGE === undefined ? 10 : parseInt(import.meta.env.VITE_DEFAULT_LISTSHOW_PER_PAGE);

