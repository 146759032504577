import { usePermissions } from 'react-admin';
import {Typography} from "@mui/material";
import {VITE_SENTRY_ENVIRONMENT} from "./config";
import {capitalize} from "lodash/string";
import { MultiLevelMenu } from '@react-admin/ra-navigation';
import { useState } from 'react';
import {
  RiPsychotherapyLine, RiPsychotherapyFill,
  RiDossierLine, RiDossierFill,
  RiCalendarEventLine, RiCalendarEventFill,
  RiFlagLine, RiFlagFill,
  RiCurrencyLine, RiCurrencyFill,
  RiSearchEyeLine, RiSearchEyeFill,
  RiCapsuleLine, RiCapsuleFill,
  RiCloudLine, RiCloudFill,
  RiFileList3Line, RiFileList3Fill,
  RiUserLine, RiUserFill,
} from "@remixicon/react";

const USER = 'user'
const SUPERVISOR = 'supervisor'
const PROV_PRACTITIONER = 'prov_practitioner'
const OFFICE_MANAGER = 'office_manager'

export const CustomMenu = () => {
  const entries = [
      {key: 'patient', name: 'patient', to: '/patient', label: 'Members',
         icon: <RiPsychotherapyLine />, activeIcon: <RiPsychotherapyFill />,
         roles: [USER, SUPERVISOR],
         children: []},
      {key: 'provider', name: 'provider', to: '', label: 'Providers',
         icon: <RiDossierLine />, activeIcon: <RiDossierFill />,
         roles: [USER, SUPERVISOR],
         children: [
            {key: 'provider_practitioner', name: 'provider_practitioner', to: '/provider_practitioner', label: 'Practitioners', icon: null, activeIcon: null,
               roles: [USER, SUPERVISOR],
               children: []},
            {key: 'gatekeeper', name: 'gatekeeper', to: '/gatekeeper', label: 'Gatekeepers', icon: null, activeIcon: null,
               roles: [SUPERVISOR],
               children: []},
         ]},
      {key: 'appointment', name: 'appointment', to: '/appointment', label: 'Appointments',
         icon: <RiCalendarEventLine />, activeIcon: <RiCalendarEventFill />,
         roles: [USER, SUPERVISOR],
         children: []},
      {key: 'coding', name: 'top_coding', to: '', label: 'Coding',
         icon: <RiFlagLine />, activeIcon: <RiFlagFill />,
         roles: [USER, SUPERVISOR, PROV_PRACTITIONER, OFFICE_MANAGER],
         children: [
            {key: 'reconciliation_report', name: 'reconciliation_report', to: '/reconciliation_report', label: 'Prospective', icon: null, activeIcon: null,
               roles: [USER, SUPERVISOR, PROV_PRACTITIONER, OFFICE_MANAGER],
               children: []},
            {key: 'reconciliation_report_query', name: 'reconciliation_report_query', to: '/reconciliation_report_query', label: 'Queries', icon: null, activeIcon: null,
               roles: [USER, SUPERVISOR],
               children: []},
            {key: 'rule', name: 'rule', to: '/rule', label: 'Rules', icon: null, activeIcon: null,
               roles: [USER, SUPERVISOR],
               children: []},
            /*{key: 'incident', name: 'incident', to: '/incident', label: 'Post Review', icon: null, activeIcon: null,
               roles: [USER, SUPERVISOR, PROV_PRACTITIONER, OFFICE_MANAGER],
               children: []},*/
         ]},
      {key: 'claim', name: 'top_claim', to: '', label: 'Claims',
         icon: <RiCurrencyLine />, activeIcon: <RiCurrencyFill />,
         roles: [USER, SUPERVISOR],
         children: [
            {key: 'payer', name: 'claim', to: '/claim', label: 'Payer claims', icon: null, activeIcon: null,
               roles: [USER, SUPERVISOR],
               children: []},
            {key: 'billing', name: 'billing_claim', to: '/billing_claim', label: 'Billing claims', icon: null, activeIcon: null,
               roles: [USER, SUPERVISOR],
               children: []},
            {key: 'visit', name: 'visit', to: '/visit', label: 'Visits', icon: null, activeIcon: null,
               roles: [USER, SUPERVISOR],
               children: []},
         ]},
      {key: 'audit', name: 'audit', to: '/audit', label: 'Audits',
         icon: <RiSearchEyeLine />, activeIcon: <RiSearchEyeFill />,
         roles: [USER, SUPERVISOR],
         children: []},
      {key: 'gaps', name: 'top_gaps', to: '', label: 'Care gaps',
         icon: <RiCapsuleLine />, activeIcon: <RiCapsuleFill />,
         roles: [USER, SUPERVISOR],
         children: [
            {key: 'gap_patient', name: 'gap_patient', to: '/gap_patient', label: 'By patient', icon: null, activeIcon: null,
               roles: [USER, SUPERVISOR],
               children: []},
            {key: 'gap', name: 'gap', to: '/gap', label: 'All', icon: null, activeIcon: null,
               roles: [USER, SUPERVISOR],
               children: []},
         ]},
      {key: 'data_sources', name: 'top_data_sources', to: '', label: 'Data Sources',
         icon: <RiCloudLine />, activeIcon: <RiCloudFill />,
         roles: [USER, SUPERVISOR],
         children: [
            {key: 'data_file', name: 'data_file', to: '/data_file', label: 'Uploads', icon: null, activeIcon: null,
               roles: [USER, SUPERVISOR],
               children: []},
            /*{key: 'file', name: 'file', to: '/file', label: 'File', icon: null, activeIcon: null,
               roles: [SUPERVISOR],
               children: []},*/
            {key: 'insurance_file', name: 'insurance_file', to: '/insurance_file', label: 'Insurance Files', icon: null, activeIcon: null,
               roles: [SUPERVISOR],
               children: []},
           {key: 'missing_insurance_file_summary', name: 'missing_insurance_file_summary', to: '/missing_insurance_file_summary', label: 'Missing Summary', icon: null, activeIcon: null,
             roles: [SUPERVISOR],
             children: []},
         ]},
      {key: 'reports', name: 'top_reports', to: '', label: 'Reporting',
         icon: <RiFileList3Line />, activeIcon: <RiFileList3Fill />,
         roles: [SUPERVISOR],
         children: [
            {key: 'bi-reports', name: 'reports.bi', to: '/reports/bi', label: 'BI Reports', icon: null, activeIcon: null,
               roles: [SUPERVISOR],
               children: []},
           {key: 'coding-reports', name: 'reports.coding', to: '', label: 'Coding Reports', icon: null, activeIcon: null,
             roles: [SUPERVISOR],
             children: [
               {key: 'coding-prov-practitioner-summary', name: 'reports.coding.prov_pract.summary', to: '/reports/coding/prov_pract_summary', label: 'PP Summary', icon: null, activeIcon: null,
                 roles: [SUPERVISOR],
                 children: []},
               {key: 'coding-by-prov-practitioner', name: 'reports.coding.query_performance_report', to: '/reports/coding/by_provider_practitioner', label: 'by Provider Practitioner', icon: null, activeIcon: null,
                 roles: [SUPERVISOR],
                 children: []},
             ]},
         ]},
    {key: 'users', name: 'users', to: '/user', label: 'Users',
      icon: <RiUserLine />, activeIcon: <RiUserFill />,
      roles: [SUPERVISOR],
      children: []},
  ];

  const { permissions } = usePermissions();
  if (permissions === undefined) return null;

  const [activeMenu, setActiveMenu] = useState(entries[0].key);
  const [mainActiveMenu, setMainActiveMenu] = useState(entries[0].key);

  const addMenuEntry = (level, entry, mainEntry) => (
      (entry.roles.some(role => permissions.includes(role))) ?
      (
         <MultiLevelMenu.Item
            key={entry.key}
            name={entry.name}
            to={entry.to}
            label={entry.label}
            icon={mainActiveMenu === entry.key ? entry.activeIcon : entry.icon}
            className={[
               'CustomMenuItem',
               `CustomMenuLevel${level}`,
               `${activeMenu === entry.key ? 'CustomMenuItemActive' : ''}`,
               `${mainActiveMenu === entry.key ? 'CustomMenuItemMainActive' : ''}`,
               `${activeMenu === entry.key && activeMenu != mainActiveMenu ? 'CustomMenuSubItemActive' : ''}`,
               `${mainActiveMenu === mainEntry.key ? 'CustomMenuItemMainTreeActive' : ''}`,
            ].join(' ')}
            onClick={() => {
               if (entry.to && entry.to.length > 0) {
                  setActiveMenu(entry.key);
                  setMainActiveMenu(mainEntry.key);
               }
            }}
         >
         {addMenuEntries(level + 1, mainEntry, entry.children)}
         </MultiLevelMenu.Item>
      ) :
      null
   );

  const addMenuEntries = (level, mainEntry, entries) => (
      (entries.length > 0) ? entries.map(
         (entry) => addMenuEntry(level, entry, mainEntry === null ? entry : mainEntry)
      ) : null
  );

  return (
   <MultiLevelMenu>
      <Typography
         variant="caption"
         color='grey'
         style={{
         marginLeft: '16px',
         marginTop: '16px',
         }}
      >
         Realm: {capitalize(VITE_SENTRY_ENVIRONMENT)}
      </Typography>
      {addMenuEntries(0, null, entries)}
   </MultiLevelMenu>
   );
}
