import {AutocompleteInput, ReferenceInput} from "react-admin";


const ProviderPractitionerInput = ({source, reference, filter, ...rest}) => {
  source = source || 'provider_practitioner_id'
  reference = reference || 'provider_practitioner'
  return (
    <ReferenceInput
      source={source}
      reference={reference}
      filter={filter}
    >
      <AutocompleteInput
        optionText='name'
        filterToQuery={(searchText) => ({ 'name@_ilike': searchText })}
        {...rest}
      />
    </ReferenceInput>
  );
}

export default ProviderPractitionerInput;
