import {
  Show,
  SimpleShowLayout,
  ReferenceField,
  TextField,
  FunctionField,
  DateField,
} from 'react-admin';
import { IncidentShowActions } from "./IncidentButtons";
import {userWithPermissions, isOfficeUser} from "../components/domain";

const IncidentUserShow = () => (
  <Show actions={<IncidentShowActions />}>
    <SimpleShowLayout>
      <ReferenceField
        source="status"
        reference="incident_status"
      >
        <TextField source="description" />
      </ReferenceField>
      <ReferenceField
        source="type"
        reference="incident_type"
      >
        <TextField source="description" />
      </ReferenceField>
      <ReferenceField
        source="appointment_id"
        reference="appointment"
        link="show"
      >
        <FunctionField
          render={
            (record) => `${record.date} ${record.patient ? record.patient.name : record.patient_name}`
          }
        />
      </ReferenceField>
      <TextField source="description" />
      <TextField source="response" />
      <DateField source="created_at" />
      <DateField source="updated_at" />
    </SimpleShowLayout>
  </Show>
);

const IncidentOfficeUserShow = () => (
  <Show actions={<IncidentShowActions />}>
    <SimpleShowLayout>
      <ReferenceField
        source="type"
        reference="incident_type"
      >
        <TextField source="description" />
      </ReferenceField>
      <ReferenceField
        source="appointment_id"
        reference="appointment"
        label="Date"
        link={false}
      >
        <DateField source="date" />
      </ReferenceField>
      <ReferenceField
        source="appointment_id"
        reference="appointment"
        label="Member"
        link={false}
      >
        <FunctionField
          render={
            (record) => `${record.patient ? record.patient.name : record.patient_name}`
          }
        />
      </ReferenceField>
      <TextField source="description" />
      <TextField source="response" />
    </SimpleShowLayout>
  </Show>
);

const IncidentShow = () => {
  if (!userWithPermissions()) return null;

  return (
    isOfficeUser() ?
    <IncidentOfficeUserShow /> :
    <IncidentUserShow />
  );
};

export default IncidentShow;
