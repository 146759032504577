import {
  List,
  Datagrid,
  SimpleShowLayout,
  ReferenceField,
  TextField,
  FunctionField,
  DateField,
  TopToolbar,
  FilterButton,
  CreateButton,
  ReferenceInput,
  SelectInput,
  DateInput,
} from 'react-admin';
import {userWithPermissions, isOfficeUser, relatedProviderIds} from "../components/domain";
import PatientInput from "../patient/PatientInput";
import ProviderPractitionerInput from "../providerpractitioner/ProviderPractitionerInput";

import IncidentAging from "./IncidentAging";
import IncidentStatus from "./IncidentStatus";
import { BulkSentByEmailButton } from "./IncidentButtons";
import {VITE_DEFAULT_LIST_PER_PAGE} from "../config";

const IncidentUserListToolbar = () => (
  <TopToolbar>
    <FilterButton />
    <CreateButton />
  </TopToolbar>
);

const IncidentOfficeUserListToolbar = () => (
  <TopToolbar>
    <FilterButton />
  </TopToolbar>
);

const IncidentPanel = () => {
  return (
    <SimpleShowLayout>
      <TextField multiline source="description" />
      <TextField multiline source="response" />
    </SimpleShowLayout>
  )
};

const IncidentsBulkActionButtons = props => (
  <>
    <BulkSentByEmailButton {...props} />
  </>
);

const IncidentUserList = () => {
  const userFilters = [
    <ReferenceInput
      label="Status"
      source="status"
      reference="incident_status"
      sort={{ field: "description", order: "ASC" }}
    >
      <SelectInput label="Status" optionText="description" />
    </ReferenceInput>,
    <ReferenceInput
      label="Type"
      source="type"
      reference="incident_type"
      sort={{ field: "description", order: "ASC" }}
    >
      <SelectInput label="Type" optionText="description" />
    </ReferenceInput>,
    <PatientInput label="Member" source="appointment#patient_id"/>,
    <ProviderPractitionerInput label="Practitioner" source='appointment#provider_practitioner_id'/>,
    <DateInput label="Created from" source="created_at@_gte"/>,
    <DateInput label="Created to" source="created_at@_lte"/>,
    <DateInput label="Updated from" source="updated_at@_gte"/>,
    <DateInput label="Updated to" source="updated_at@_lte"/>,
  ];

  return (
    <List
      exporter={false}
      filters={userFilters}
      actions={<IncidentUserListToolbar />}
      perPage={VITE_DEFAULT_LIST_PER_PAGE}
    >
      <Datagrid
        rowClick='show'
        expand={<IncidentPanel/>}
        bulkActionButtons={<IncidentsBulkActionButtons/>}
      >
        <IncidentAging label="Working status" />
        <IncidentStatus label="Status" />
        <ReferenceField source="type" reference="incident_type">
          <TextField source="description" />
        </ReferenceField>
        <ReferenceField
          source="appointment_id"
          reference="appointment"
          link="show"
        >
          <FunctionField
            render={
              (record) => `${record.date} ${record.patient ? record.patient.name : record.patient_name}`
            }
          />
        </ReferenceField>
        <DateField source="created_at"/>
        <DateField source="updated_at"/>
      </Datagrid>
    </List>
  );
};

const IncidentOfficeUserList = () => {
  const officeUserFilters = [
    <ReferenceInput
      label="Type"
      source="type"
      reference="incident_type"
      sort={{ field: "description", order: "ASC" }}
    >
      <SelectInput label="Type" optionText="description" />
    </ReferenceInput>,
    <PatientInput label="Member" source="appointment#patient_id"/>,
    <ProviderPractitionerInput
      label="Practitioner"
      source='appointment#provider_practitioner_id'
      filter={{ id: relatedProviderIds() }}
    />,
    <DateInput label="Date from" source="appointment#date@_gte"/>,
    <DateInput label="Date to" source="appointment#date@_lte"/>,
  ];

  return (
    <List
      exporter={false}
      filters={officeUserFilters}
      actions={<IncidentOfficeUserListToolbar />}
      perPage={VITE_DEFAULT_LIST_PER_PAGE}
    >
      <Datagrid
        rowClick='show'
        expand={<IncidentPanel/>}
        bulkActionButtons={false}
      >
        <ReferenceField source="type" reference="incident_type">
          <TextField source="description" />
        </ReferenceField>
        <ReferenceField
          source="appointment_id"
          reference="appointment"
          label="Date"
          link={false}
        >
          <DateField source="date" />
        </ReferenceField>
        <ReferenceField
          source="appointment_id"
          reference="appointment"
          label="Member"
          link={false}
        >
          <FunctionField
            render={
              (record) => `${record.patient ? record.patient.name : record.patient_name}`
            }
          />
        </ReferenceField>
      </Datagrid>
    </List>
  );
};

const IncidentList = () => {
  if (!userWithPermissions()) return null;

  return (
    isOfficeUser() ?
    <IncidentOfficeUserList /> :
    <IncidentUserList />
  );
};

export default IncidentList;
