import React, { useState } from 'react';
import { Grid, Paper, Typography, Button, Autocomplete, TextField } from '@mui/material';
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';
import { DataGrid } from '@mui/x-data-grid';
import { CubeProvider, useCubeQuery } from '@cubejs-client/react';
import cubejs from '@cubejs-client/core';
import { PieChart, Pie, Cell, ResponsiveContainer, Tooltip, BarChart, Bar, XAxis } from 'recharts';
import {VITE_CUBEJS_API_URL, VITE_CUBEJS_TOKEN} from "../config";

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#8884d8'];

Number.prototype.toLocaleFixed = function(n) {
  return this.toLocaleString(undefined, {
    minimumFractionDigits: n,
    maximumFractionDigits: n
  });
};


const buildFilters = (selectedConditions, additionalFilters = []) => {
  const filters = [...additionalFilters];
  
  if (selectedConditions?.length > 0) {
    filters.push({
      member: "condition_v28.id",
      operator: "equals",
      values: selectedConditions.map(c => c.id)
    });
  }

  return filters;
};

const UsersPieChart = ({ dateRange, selectedConditions }) => {
  const { resultSet, isLoading, error } = useCubeQuery({
    measures: ["queried_condition_result.count"],
    dimensions: ["user.name"],
    timeDimensions: [{
      dimension: "queried_condition_result.appointment_date",
      dateRange: [
        dateRange[0].format('YYYY-MM-DD'),
        dateRange[1].format('YYYY-MM-DD')
      ]
    }],
    filters: buildFilters(selectedConditions)
  });

  console.log('Pie chart query state:', { isLoading, error, resultSet });

  if (isLoading) return <div>Loading...</div>;
  if (error) return <div>Error: {error.toString()}</div>;
  if (!resultSet) return <div>No result set available</div>;

  const data = resultSet.tablePivot().map((row) => ({
    name: row['user.name'],
    value: parseInt(row['queried_condition_result.count'])
  }));

  console.log('Pie chart transformed data:', data);

  return (
    <ResponsiveContainer width="100%" height={200}>
      <PieChart >
        <Pie
          data={data}
          dataKey="value"
          nameKey="name"
          startAngle={180}
          endAngle={0}
          innerRadius={60}
          outerRadius={120}
          fill="green"
          cy={"80%"}
        >
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
          ))}
        </Pie>
        <Tooltip />
      </PieChart>
    </ResponsiveContainer>
  );
};

const PerformanceTable = ({ dateRange, onRowClick, drillDownMode, selectedProvider, selectedConditions }) => {
  const additionalFilters = selectedProvider && drillDownMode === 'condition' 
    ? [{
        member: "provider_practitioner.name",
        operator: "equals",
        values: [selectedProvider.provider]
      }]
    : [];

  const { resultSet, isLoading, error } = useCubeQuery({
    measures: [
      "queried_condition_result.count",
      "queried_condition_result.billed_count",
      "queried_condition_result.billed_ratio"
    ],
    dimensions: drillDownMode === 'condition' 
      ? [
          "provider_practitioner.name",
          "condition_v28.id",
          "condition_v28.description"
        ]
      : ["provider_practitioner.name"],
    filters: buildFilters(selectedConditions, additionalFilters),
    timeDimensions: [{
      dimension: "queried_condition_result.appointment_date",
      dateRange: [
        dateRange[0].format('YYYY-MM-DD'),
        dateRange[1].format('YYYY-MM-DD')
      ]
    }],
    order: {
      "provider_practitioner.name": "asc"
    }
  });

  if (isLoading) return <div>Loading...</div>;
  if (error) return <div>Error: {error.toString()}</div>;
  if (!resultSet) return <div>No result set available</div>;

  const rows = resultSet.tablePivot().map((row, index) => ({
    id: index,
    provider: row['provider_practitioner.name'],
    ...(drillDownMode === 'condition' && {
      conditionId: row['condition_v28.id'],
      conditionDesc: row['condition_v28.description'],
    }),
    queries: row['queried_condition_result.count'],
    billed: row['queried_condition_result.billed_count'],
    billedRatio: row['queried_condition_result.billed_ratio'],
  }));

  const columns = drillDownMode === 'condition' 
    ? [
        { field: 'conditionId', headerName: 'Condition ID', width: 130 },
        { field: 'conditionDesc', headerName: 'Description', flex: 1 },
        { field: 'queries', headerName: 'Queries', type: 'number', width: 130 },
        { field: 'billed', headerName: 'Billed', type: 'number', width: 130 },
        { 
          field: 'billedRatio', 
          headerName: 'Billed %', 
          width: 130,
          align: 'right',
          headerAlign: 'right',
          valueFormatter: (params) => {
            if (params.value == null) {
              return '';
            }
            return `${params.value.toLocaleFixed(1)} %`;
          }
        }
      ]
    : [
        { field: 'provider', headerName: 'Provider', flex: 1 },
        { field: 'queries', headerName: 'Queries', type: 'number', width: 130 },
        { field: 'billed', headerName: 'Billed', type: 'number', width: 130 },
        { 
          field: 'billedRatio', 
          headerName: 'Billed %', 
          width: 130,
          align: 'right',
          headerAlign: 'right',
          valueFormatter: (params) => {
            if (params.value == null) {
              return '';
            }
            return `${params.value.toLocaleFixed(1)} %`;
          }
        }
      ];

  return (
    <div style={{ height: 600, width: '100%' }}>
      <DataGrid
        rows={rows}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: { pageSize: 5, page: 0 },
          },
        }}
        pageSizeOptions={[5]}
        onRowClick={(params) => onRowClick(params.row)}
        density="compact"
      />
    </div>
  );
};

const WeeklyQueriesChart = ({ dateRange, selectedConditions }) => {
  const { resultSet, isLoading, error } = useCubeQuery({
    measures: [
      "queried_condition_result.count"
    ],
    dimensions: [
      "user.name"
    ],
    timeDimensions: [{
      dimension: "queried_condition_result.appointment_date",
      granularity: "week",
      dateRange: "last 8 weeks"
    }],
    filters: buildFilters(selectedConditions)
  });

  if (isLoading) return <div>Loading...</div>;
  if (error) return <div>Error: {error.toString()}</div>;
  if (!resultSet) return <div>No result set available</div>;

  // Transform data for stacked bar chart
  const pivotData = resultSet.tablePivot();
  const users = [...new Set(pivotData.map(row => row['user.name']))];
  
  const data = pivotData.reduce((acc, row) => {
    const weekIndex = acc.findIndex(item => item.week === row['queried_condition_result.appointment_date.week']);
    if (weekIndex === -1) {
      acc.push({
        week: row['queried_condition_result.appointment_date.week'],
        [row['user.name']]: row['queried_condition_result.count']
      });
    } else {
      acc[weekIndex][row['user.name']] = row['queried_condition_result.count'];
    }
    return acc;
  }, []);

  return (
    <ResponsiveContainer width="100%" height={200}>
      <BarChart data={data}>
        {/*<CartesianGrid strokeDasharray="3 3" />*/}
        <XAxis
          dataKey="week"
          tickFormatter={(value) => {
            const date = new Date(value);
            return `${date.getMonth() + 1}/${date.getDate()}`;
          }}
        />
        {/*<YAxis />*/}
        <Tooltip />
        {users.map((user, index) => (
          <Bar 
            key={user}
            dataKey={user}
            stackId="a"
            fill={COLORS[index % COLORS.length]}
          />
        ))}
      </BarChart>
    </ResponsiveContainer>
  );
};

const ConditionSelector = ({ selectedConditions, onChange }) => {
  const { resultSet, isLoading, error } = useCubeQuery({
    dimensions: [
      "condition_v28.id",
      "condition_v28.description"
    ],
    order: {
      "condition_v28.id": "asc"
    }
  });

  if (isLoading) return <TextField label="Loading conditions..." disabled />;
  if (error) return <TextField label="Error loading conditions" disabled error />;
  if (!resultSet) return null;

  const conditions = resultSet.tablePivot().map(row => ({
    id: row['condition_v28.id'],
    description: row['condition_v28.description'],
    label: `${row['condition_v28.id']} - ${row['condition_v28.description']}`
  }));

  return (
    <Autocomplete
      multiple
      options={conditions}
      value={selectedConditions}
      onChange={(event, newValue) => onChange(newValue)}
      getOptionLabel={(option) => option.label}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Filter by Conditions"
          placeholder="Select conditions"
        />
      )}
      sx={{ width: '100%' }}
    />
  );
};

const QueryPerformanceReport = () => {
  const [dateRange, setDateRange] = useState([
    dayjs().subtract(1, 'month').startOf('month'),
    dayjs()
  ]);
  const [selectedConditions, setSelectedConditions] = useState([]);
  const [selectedProvider, setSelectedProvider] = useState(null);
  const [drillDownMode, setDrillDownMode] = useState(null);

  const cubejsClient = cubejs(VITE_CUBEJS_TOKEN, {
    apiUrl: VITE_CUBEJS_API_URL
  });

  return (
    <CubeProvider cubeApi={cubejsClient}>
      <Typography variant="h4" gutterBottom>
        Query Performance Report
      </Typography>
      <Paper sx={{ p: 2, mb: 3 }}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <DateRangePicker
                calendars={2}
                value={dateRange}
                onChange={(newValue) => setDateRange(newValue)}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <ConditionSelector
                selectedConditions={selectedConditions}
                onChange={setSelectedConditions}
              />
            </Grid>
          </Grid>
        </LocalizationProvider>
      </Paper>
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <Paper sx={{ p: 2 }}>
            <Typography variant="h6" gutterBottom>
              Queries by User
            </Typography>
            <UsersPieChart dateRange={dateRange} selectedConditions={selectedConditions} />
          </Paper>
        </Grid>
        <Grid item xs={6}>
          <Paper sx={{ p: 2 }}>
            <Typography variant="h6" gutterBottom>
              Weekly Queries by User
            </Typography>
            <WeeklyQueriesChart dateRange={dateRange} selectedConditions={selectedConditions} />
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Paper sx={{ p: 2 }}>
            <Typography variant="h6" gutterBottom>
              Performance Data
            </Typography>
            <div>
              {selectedProvider && (
                <Paper sx={{ p: 1, mb: 2, backgroundColor: '#f5f5f5' }}>
                  <Typography variant="subtitle2">
                    Selected Provider: {selectedProvider.provider}
                    <Button
                      size="small"
                      sx={{ ml: 2 }}
                      onClick={() => {
                        setSelectedProvider(null);
                        setDrillDownMode(null);
                      }}
                    >
                      Clear Selection
                    </Button>
                  </Typography>
                  {!drillDownMode && (
                    <Typography variant="subtitle2">
                      Drill down into:{' '}
                      <Button
                        size="small"
                        onClick={() => setDrillDownMode('condition')}
                      >
                        Condition
                      </Button>
                    </Typography>
                  )}
                </Paper>
              )}
              <PerformanceTable
                dateRange={dateRange}
                onRowClick={(row) => {
                  setSelectedProvider(row);
                  setDrillDownMode(null);
                }}
                drillDownMode={drillDownMode}
                selectedProvider={selectedProvider}
                selectedConditions={selectedConditions}
              />
            </div>
          </Paper>
        </Grid>
      </Grid>
    </CubeProvider>
  );
};

export default QueryPerformanceReport;
