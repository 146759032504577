import {
  TopToolbar,
  EditButton,
  Button,
  useRecordContext,
  usePermissions,
  useDataProvider,
  useListContext,
  useNotify,
  useRefresh,
} from "react-admin";
import {useState} from "react";
import {useNavigate} from "react-router-dom";
import { useApolloClient, gql } from '@apollo/client';
import CloseIcon from '@mui/icons-material/Cancel';
import ResolveIcon from '@mui/icons-material/CheckCircle';
import EmailIcon from '@mui/icons-material/Email';

import {
  canEdit,
  canResolveUser,
  canResolveOfficeUser,
  canClose,
  canSendByEmail
} from "./domain";

export const IncidentShowActions = (props) => {
  const record = useRecordContext();
  const { permissions } = usePermissions();

  if (record === undefined) return null;
  if (permissions === undefined) return null;

  return (
    <TopToolbar>
      { canEdit(record.status) ? <EditButton /> : null }
      { canSendByEmail(record) ? <SendByEmailButton /> : null }
      { canResolveUser(record.status) ? <ResolveButton /> : null }
      { canResolveOfficeUser(record.status) ? <ResolveByProvButton /> : null }
      { canClose(record.status) ? <CloseButton /> : null }
    </TopToolbar>
  );
};

export const ChangeStateButton = ({newStatus, label, icon, doRefresh}) => {
  const incident = useRecordContext();
  const refresh = useRefresh();
  const navigate = useNavigate();
  const dataProvider = useDataProvider();
  let [running, setRunning] = useState(false);

  let handleChange = (event) => {
    event.stopPropagation();
    setRunning(true);
    dataProvider.update(
        'incident',
        {id: incident.id, data: {status: newStatus}}
      ).then(() => {
        if (doRefresh) {
          refresh();
        }
        else {
          navigate('/incident');
        }
      }).finally(() => {
        setRunning(false);
      })
  }

  return (
    <Button
      startIcon={icon}
      onClick={handleChange}
      disabled={running}
      label={label}
    />
  );
};

export const ResolveButton = () => (
  <ChangeStateButton
    newStatus="resolved"
    label="Mark Resolved"
    icon={<ResolveIcon />}
    doRefresh={true}
  />
);

export const ResolveByProvButton = () => (
  <ChangeStateButton
    newStatus="resolved_prov"
    label="Mark Resolved"
    icon={<ResolveIcon />}
    doRefresh={false}
  />
);

export const CloseButton = () => (
  <ChangeStateButton
    newStatus="closed"
    label="Mark Closed"
    icon={<CloseIcon />}
    doRefresh={true}
  />
);

export const SendByEmailButton = () => {
  const incident = useRecordContext();
  const refresh = useRefresh();
  const apolloClient = useApolloClient();
  const { permissions } = usePermissions();
  let [running, setRunning] = useState(false);

  if (!canSendByEmail(incident)) return null;

  const handleSendByEmail = (event) => {
    event.stopPropagation();
    setRunning(true);
    apolloClient.mutate({
        mutation: gql`
          mutation sendIncidentsByEmail($ids: [Int]!) {
            send_incidents_by_email(ids: $ids) {
              message
            }
          }
        `,
        variables: {
          ids: [incident.id]
        }
      }).then(() => {
        refresh();
      }).finally(() => {
        setRunning(false);
      });
  }

  return (
    <Button
      startIcon={<EmailIcon/>}
      onClick={handleSendByEmail}
      disabled={running}
      label="Send by email"/>
  );
};

export const BulkSentByEmailButton = () => {
  const notify = useNotify();
  const refresh = useRefresh();
  const { selectedIds, resource } = useListContext();
  const apolloClient = useApolloClient();
  const [running, setRunning] = useState(false);

  const sendSelectedByEmail = async () => {
    setRunning(true);
    apolloClient.mutate({
        mutation: gql`
          mutation sendIncidentsByEmail($ids: [Int]!) {
            send_incidents_by_email(ids: $ids) {
              message
            }
          }
        `,
        variables: {
          ids: selectedIds
        }
      }).then(() => {
        notify(`All incidents sent by email`, { type: 'success' });
      }).catch((e) => {
        notify(`Error sending incidents by email`, { type: 'error' });
        console.log('error sending incidents by email')
        console.log(e)
      }).finally(() => {
        refresh();
        setRunning(false);
      });
  };

  return (
    <Button
      label="Send all by email"
      disabled={running}
      onClick={() => sendSelectedByEmail()}
    >
      <EmailIcon />
    </Button>
  );
};
