import { usePermissions, useDataProvider } from 'react-admin';
import {useState} from 'react';

import {getUserId} from '../auth/sapiensUser';

export const hasPermissions = (permissions) => {
  return permissions !== null;
};

export const hasUserPermissions = (permissions) => {
  return (
    permissions.includes('user') ||
    permissions.includes('supervisor')
  );
};

export const hasOfficeUserPermissions = (permissions) => {
  return permissions.length <= 1 && (
    permissions.includes('prov_practitioner') ||
    permissions.includes('office_manager')
  );
};

export const userWithPermissions = () => {
  const { permissions } = usePermissions();
  return hasPermissions(permissions);
};

export const isUser = () => {
  const { permissions } = usePermissions();
  return hasPermissions(permissions) &&
    hasUserPermissions(permissions);
};

export const isOfficeUser = () => {
  const { permissions } = usePermissions();
  return hasPermissions(permissions) &&
    hasOfficeUserPermissions(permissions);
};

export const relatedProviderIds = () => {
  const dataProvider = useDataProvider();
  const [userId, setUserId] = useState(null);
  const [ownProviderIds, setOwnProviderIds] = useState(null);
  const [refProviderIds, setRefProviderIds] = useState(null);

  if (userId === null) {
    getUserId().then((id) => setUserId(parseInt(id)));
  }

  if (userId !== null) {
    dataProvider.getList(
      'provider_practitioner',
      { filter: { user_id: userId } }
    ).then((response) => {
      setOwnProviderIds(response['data'].map((pp) => pp.id));
    });

    dataProvider.getList(
      'office_manager_provider',
      { filter: { user_id: userId } }
    ).then((response) => {
      setRefProviderIds(response['data'].map((omp) => omp.provider_practitioner_id));
    });
  }

  if (ownProviderIds === null && refProviderIds === null) return [];
  else if (ownProviderIds === null) return refProviderIds;
  else if (refProviderIds === null) return ownProviderIds;
  else return [...new Set([...ownProviderIds, ...refProviderIds])]
};
