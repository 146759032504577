import {
  ReferenceField,
  ChipField,
  useRecordContext,
} from 'react-admin';

const IncidentStatus = () => {
  const record = useRecordContext();
  let chipProps = {size: 'small', sx: {borderRadius: '8px'}};

  if (record.status === 'resolved') {
    chipProps.color = 'sapiensSuccess';
  }
  else if (record.status === 'closed') {
    chipProps.color = 'sapiensError';
  }
  else {
    // status: open, sent
    chipProps.color = 'sapiensWarning';
  }

  return (
    <ReferenceField source="status" reference="incident_status">
      <ChipField source="description" {...chipProps}/>
    </ReferenceField>
  );
};

export default IncidentStatus;
