import {isUser, isOfficeUser} from "../components/domain";

export const canEditUser = (status) => {
  return isUser() && status === 'open';
}

export const canEditOfficeUser = (status) => {
  return isOfficeUser() && status === 'sent';
}

export const canEdit = (status) => {
  return canEditUser(status) || canEditOfficeUser(status);
}

export const canSend = (status) => {
  return isUser() && status === 'open';
}

export const canResolveUser = (status) => {
  return isUser() && (
    status === 'open' ||
    status === 'sent' ||
    status === 'resolved_prov'
  );
}

export const canResolveOfficeUser = (status) => {
  return isOfficeUser() && status === 'sent';
}

export const canClose = (status) => {
  return isUser() && (
    status === 'open' ||
    status === 'sent' ||
    status === 'resolved_prov'
  );
}

export const canSendByEmail = (incident) => {
  return canSend(incident.status) && !!incident.appointment.provider_practitioner?.email;
};
