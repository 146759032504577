import {
  List,
  Datagrid,
  TextField,
  NumberField,
  ReferenceField,
  Button,
  useRecordContext, ReferenceArrayInput, AutocompleteArrayInput
} from "react-admin";
import {VITE_DEFAULT_LIST_PER_PAGE} from "../config";
import ArrowCircleDown from "@mui/icons-material/ArrowCircleDown";
import {Link} from "react-router-dom";

const providerFilters = [
  <ReferenceArrayInput source="provider_practitioner#gatekeeper_id" reference="gatekeeper" alwaysOn sort={{field: 'name', order: 'ASC'}}>
    <AutocompleteArrayInput
      label='Gatekeeper'
      fullWidth
      optionText="name"
      filterToQuery={(searchText) => ({ "name@_ilike": searchText })}
      sx={{minWidth: 350}}
    />
  </ReferenceArrayInput>,
  <ReferenceArrayInput source="provider_practitioner_id" reference="provider_practitioner" alwaysOn sort={{field: 'name', order: 'ASC'}} >
    <AutocompleteArrayInput
      label='Prov. practitioner'
      fullWidth
      optionText="name"
      filterToQuery={(searchText) => ({ "name@_ilike": searchText })}
      sx={{minWidth: 350}}
    />
  </ReferenceArrayInput>,
];

const DetailsButton = (props) => {
  const {
    label = 'Details',
    ...rest
  } = props;
  const record = useRecordContext(props);
  if (!record) return null;
  const filter = {
    'provider_practitioner_id': record.provider_practitioner_id,
  }
  return (
    <Button
      component={Link}
      to={`/reports/coding/prov_pract_detail_1?filter=${JSON.stringify(filter)}`}
      label={label}
      onClick={(e) => e.stopPropagation()}
      startIcon={<ArrowCircleDown />}
      {...rest}
    />
  );
};

const CodingPPSummary = () => {
  return(
    <List perPage={VITE_DEFAULT_LIST_PER_PAGE} filters={providerFilters}
          title={"Provider Practitioner Response Ratio Summary"}
          resource='prov_practitioner_queried_billed_hcc28_view_summary'
          sort={{field: 'effective_ratio', order: 'DESC'}}>
      <Datagrid rowClick={false} bulkActionButtons={false}>
        <ReferenceField label='Gatekeeper' reference='gatekeeper' source="provider_practitioner.gatekeeper_id" link={false}>
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField reference='provider_practitioner' source="provider_practitioner_id" link={false}>
          <TextField source="name" />
        </ReferenceField>
        <TextField source="effective_cnt" label="Queries responded" />
        <TextField source="negative_cnt" label="Queries not responded" />
        <TextField source="total_cnt" label="Queries total" />
        <NumberField source="effective_ratio" label="Ratio"
                     options={{'maximumFractionDigits': 1, 'minimumFractionDigits': 1}} />
        <DetailsButton />
      </Datagrid>
    </List>
  );
}

export default CodingPPSummary;
