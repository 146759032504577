import {ReferenceInput, SelectInput} from "react-admin";


const InsuranceProviderInput = ({source = 'insurance_provider_id', reference='insurance_provider', ...rest}) => {
  /**
   * Select Input to select one insurance provider
   *
   * @type {string}
   */

  return (
    <ReferenceInput
      label='Insurance provider'
      reference={reference}
      source={source}
      sort={{ field: 'description', order: 'ASC' }}
    >
      <SelectInput optionText='description' {...rest}/>
    </ReferenceInput>
  )
}

export default InsuranceProviderInput;
