import {
  Create,
  SimpleForm,
  TextInput,
  ReferenceInput,
  SelectInput,
  AutocompleteInput,
  required,
} from 'react-admin';

const IncidentCreate = () => (
  <Create>
    <SimpleForm>
      <ReferenceInput
        source="type"
        reference="incident_type"
        sort={{ field: "description", order: "ASC" }}
      >
        <SelectInput optionText="description" validate={required()} />
      </ReferenceInput>
      <ReferenceInput
        source="appointment_id"
        reference="appointment"
        sort={{ field: "date", order: "DESC" }}
      >
        <AutocompleteInput
          optionText={(appointment) => appointment?.id // the empty choice is { id: '' }
                  ? `${appointment.date} ${appointment.patient ? appointment.patient.name : appointment.patient_name}` :
                  ''}
          filterToQuery={(searchText) => ({ "patient_name@_ilike,patient#name@_ilike": searchText })}
          validate={required()}
        />
      </ReferenceInput>
      <TextInput fullWidth multiline source="description"/>
    </SimpleForm>
  </Create>
);

export default IncidentCreate;
