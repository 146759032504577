import {
  List,
  Datagrid,
  TextField,
  ReferenceField,
  Button,
  DateField,
  FunctionField,
  useRecordContext,
  ReferenceArrayInput,
  AutocompleteArrayInput, NullableBooleanInput,
} from "react-admin";
import {VITE_DEFAULT_LIST_PER_PAGE} from "../config";
import {Link} from "react-router-dom";

const providerFilters = [
  <ReferenceArrayInput source="gatekeeper_id" reference="gatekeeper" alwaysOn sort={{field: 'name', order: 'ASC'}}>
    <AutocompleteArrayInput
      label='Gatekeeper'
      fullWidth
      optionText="name"
      filterToQuery={(searchText) => ({ "name@_ilike": searchText })}
    />
  </ReferenceArrayInput>,
  <ReferenceArrayInput source="patient_id" reference="patient" alwaysOn sort={{field: 'name', order: 'ASC'}} >
    <AutocompleteArrayInput
      label='Prov. practitioner'
      fullWidth
      optionText="name"
      filterToQuery={(searchText) => ({ "name@_ilike": searchText })}
    />
  </ReferenceArrayInput>,
  <NullableBooleanInput label='Billed in same visit' source='billed_in_visit' alwaysOn fullWidth
                        sx={{minWidth: 130}}/>,
];

export const QueriedButton = (props) => {
  const {
    label = 'Queried',
    ...rest
  } = props;
  const record = useRecordContext(props);
  if (!record) return null;
  const filter = {
    'provider_practitioner_id': record.provider_practitioner_id,
    'next_appointment_date': record.appointment_date,
    'state@_in': ['sent', 'answered'],
    'patient_id': record.patient_id,
  }
  return (
    <Button
      component={Link}
      to={`/reconciliation_report?filter=${JSON.stringify(filter)}`}
      label={label}
      onClick={(e) => e.stopPropagation()}
      {...rest}
    />
  );
};

export const BilledButton = (props) => {
  const {
    label = 'Billed',
    ...rest
  } = props;
  const record = useRecordContext(props);
  if (!record) return null;
  const filter = {
    'gatekeeper_id': record.gatekeeper_id,
    'service_date': record.appointment_date,
    'patient_id': record.patient_id,
    'billed_status': 'SUBMITTED',
  }
  return (
    <Button
      component={Link}
      to={`/billing_claim?filter=${JSON.stringify(filter)}`}
      label={label}
      onClick={(e) => e.stopPropagation()}
      disabled={!record.billed_in_visit}
      {...rest}
    />
  );
};

const CodingPPDetail = () => {
  return(
    <List perPage={VITE_DEFAULT_LIST_PER_PAGE} filters={providerFilters}
          title={"Provider Practitioner Response Ratio Detail"}
          resource='prov_practitioner_queried_billed_hcc28_view'>
      <Datagrid rowClick={false} bulkActionButtons={false}>
        <ReferenceField reference='gatekeeper' source="gatekeeper_id" link={false}>
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField reference='provider_practitioner' source="provider_practitioner_id" link={false}>
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField label='Member' reference='patient' source="patient_id" link={false}>
          <TextField source="name" />
        </ReferenceField>
        <DateField source='appointment_date' label='Service date'/>
        <TextField source='hcc_id' label='HCC ID'/>
        <ReferenceField source="helper_hcc_id" reference="condition" label="HCC">
          <TextField source="description" />
        </ReferenceField>
        <FunctionField label="Billed in same visit" render={(record) => record.billed_in_visit ? 'True' : 'False'}/>
        <div>
          <QueriedButton />
          <BilledButton />
        </div>
      </Datagrid>
    </List>
  );
}

export default CodingPPDetail;
