import {
  Show,
  TextField,
  SimpleShowLayout,
  ReferenceManyField,
  Pagination,
  Datagrid,
  ReferenceField,
  BooleanField,
} from "react-admin";
import GatekeeperReferenceField from "../gatekeeper/GatekeeperReferenceField";
import {VITE_DEFAULT_LISTSHOW_PER_PAGE} from "../config";

export const UserShow = () => {
  return (
    <Show>
      <SimpleShowLayout>
        <TextField source="id" />
        <TextField source="name" />
        <TextField source="email" />
        <ReferenceManyField
          label="Roles"
          reference='user_role'
          target='user_id'
          perPage={VITE_DEFAULT_LISTSHOW_PER_PAGE}
          pagination={<Pagination />}
        >
          <Datagrid bulkActionButtons={false} rowClick={false}>
            <ReferenceField
              reference="role"
              source="role_id"
            >
              <TextField source="description" />
            </ReferenceField>
            <BooleanField source="is_default" />
          </Datagrid>
        </ReferenceManyField>
        <ReferenceManyField
          label="Practitioners"
          reference='office_manager_provider'
          target='user_id'
          perPage={VITE_DEFAULT_LISTSHOW_PER_PAGE}
          pagination={<Pagination />}
        >
          <Datagrid bulkActionButtons={false} rowClick={false}>
            <ReferenceField
              reference="provider_practitioner"
              source="provider_practitioner_id"
              link={false}
            >
              <TextField source="name" />
            </ReferenceField>
            <ReferenceField
              reference="provider_practitioner"
              source="provider_practitioner_id"
            >
              <GatekeeperReferenceField />
            </ReferenceField>
          </Datagrid>
        </ReferenceManyField>
      </SimpleShowLayout>
    </Show>
  );
}

export default UserShow;
