import {useRecordContext} from 'react-admin';
import {Chip, Tooltip} from '@mui/material';

const IncidentAging = () => {
  const record = useRecordContext();
  const today = new Date();
  const refDate = new Date(record.created_at);
  const dateDiff = Math.floor((today - refDate) / (1000*60*60*24));
  let chipProps = {size: 'small', sx: {borderRadius: '8px'}};
  let tooltipTitle;

  if (['open', 'sent'].includes(record.status)) {
    if (refDate <= today) {
      chipProps.label = 'Pending';
      if (dateDiff < 15) {
        chipProps.color = 'sapiensSuccess';
        tooltipTitle = 'Less than 15 days';
      }
      else if (dateDiff < 30) {
        chipProps.color = 'sapiensWarning';
        tooltipTitle = 'Less than 30 days';
      }
      else if (dateDiff >= 30) {
        chipProps.color = 'sapiensError';
        tooltipTitle = 'More than 30 days';
      }
    }
  }
  else if (record.status === 'resolved_prov') {
    chipProps.color = 'sapiensInfo';
    chipProps.label = 'Resolved by provider';
    tooltipTitle = 'Resolved by provider';
  }
  else {
    chipProps.color = 'sapiensInfo';
    chipProps.label = 'Finished';
    tooltipTitle = record.status;
  }
  return (
    <Tooltip title={tooltipTitle} placement='top-end'>
      <Chip {...chipProps}/>
    </Tooltip>
  );
};

export default IncidentAging;
